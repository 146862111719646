<template>

  <section id="news">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-newspaper color-secondary"></i>
          Conoce todas las noticias que están publicadas en LinkiWork. 
          Podrás <strong>revisar</strong>, <strong>editar</strong> o <strong>eliminar</strong> 
          alguna si lo requieres.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/noticias/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear noticia
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-7 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar noticia" 
                v-model.trim="search_news" 
                @keyup.enter="searchNewsNE()"
                @keyup.delete="checkEmptyInput()"/>
                <span class="input-search-icon" @click="searchNewsNE()">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-5">
            <v-select
              class="selvue-custom"
              :clearable="false"
              :options="data_categoria" 
              v-model="sel_categoria"
              placeholder="Categoría">
              <span slot="no-options"></span>
            </v-select>     
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
              icon="filter" 
              class="color-secondary pe-2"
            />
            Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormat(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormat(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-if="show_spinner" />
    <div
      class="row" 
      v-show="newsArr.length > 0 && no_results == ''"
    >
      <div 
        class="col-12 col-md-6 col-lg-4" 
        v-for="(n, i) in newsArr" :key="i">
        <article class="card card-item card-shadow border-round-10 border-0 mb-3">
          <figure class="card-item-figure">
              <div class="card-item-config">
                <a 
                  href="javascript:" 
                  @click="(noticia_selected = n)"
                  data-bs-toggle="modal" 
                  data-bs-target="#news-detail"
                  class="card-item-config-btn"
                  content="Ver noticia" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="eye"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="editNoticia(n)"
                  class="card-item-config-btn"
                  content="Editar noticia" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="pen"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="openDeleteModal(n)"
                  class="card-item-config-btn"
                  content="Eliminar noticia" 
                  v-tippy="{ arrow: true }" 
                >
                  <font-awesome-icon icon="trash-alt"/>
                </a>
              </div>
              <div 
                class="card-item-future" 
                v-if="futureNew(n.fecha_noticia)"
              >
                <p class="mb-0 text-center text-white">
                  Esta noticia se publicará <br>
                  el <strong>{{ dateNewFormat(n.fecha_noticia, 1) }}</strong><br>
                  a las <strong>{{ hourFormat(n.fecha_noticia) }}</strong>
              </p>
              </div>
              <span class="card-item-date">
                {{ dateNewFormat(n.fecha_noticia, 1) }}
              </span>
              <img 
                :src="n.url_imagen_principal" 
                class="card-item-figure-img"
              >
          </figure>
          <div class="card-body card-item-excerpt">
            <h5 class="card-item-title-2 font-main color-gray mb-0">
              {{ n.titulo_noticia }}
            </h5>
          </div>
        </article>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-info text-center" v-show="no_results !== ''">
          <font-awesome-icon icon="circle-info"/>
          {{ no_results }}
        </div>
      </div>
    </div>

    <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
      <paginate v-model="pagination.actual_page" :page-count="pagination.page_count" :page-range="pagination.page_range"
        :click-handler="pagination.click_handler" :prev-text="pagination.prev_text" :next-text="pagination.next_text"
        :container-class="pagination.container_class" :page-class="pagination.page_class">
      </paginate>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      tabindex="-1" 
      aria-hidden="true"
      id="news-detail">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <font-awesome-icon icon="newspaper" class="color-main"/>
              <span 
                v-for="(dt, i) in dateNewFormat(noticia_selected.fecha_noticia, 2)
                .split(';')" :key="i" :class="`date-${i == 0 ? 'a' : 'b'}`">
                {{ dt }}
            </span>
            </h1>
            <button 
              type="button" 
              class="modal-custom-close"
              data-bs-dismiss="modal" 
              aria-label="Close">
              <font-awesome-icon icon="xmark"/>
            </button>
          </div>
          <div class="modal-body">

            <div class="row">
              <div class="col-12">
                <figure>
                  <img 
                    :src="noticia_selected.url_imagen_principal"
                    class="modal-custom-img border"
                  />
                </figure>
                <h4 class="modal-custom-title">
                  {{ noticia_selected.titulo_noticia }}
                </h4>
              </div>
            </div>

            <div 
              class="row"
              v-for="(content, k) in noticia_selected.contenido" 
              :key="k"
            >
              <div class="col-12">

                <!--TITLE-->
                <div 
                  class="modal-custom-text" 
                  v-if="content.texto !== null && content.tipo_contenido[0].id_tipo_contenido == '1'" 
                  v-html="content.texto">
                </div>

                <!--IMAGE-->
                <div 
                  class="image" 
                  v-if="content.url_imagen !== null && content.tipo_contenido[0].id_tipo_contenido == '2'"
                >
                  <a 
                    class="modal-custom-link-img"
                    v-if="content.url_externo_intranet != ''"
                    :href="content.url_externo_intranet"
                    :id="`img[${k}]`" 
                    target="_blank" 
                  >
                    <figure>
                      <img 
                        class="modal-custom-img border"
                        :src="content.url_imagen"
                      />
                    </figure>
                  </a>
                  <figure v-if="content.url_externo_intranet == ''">
                    <img 
                      class="modal-custom-img border"
                      :src="content.url_imagen" 
                    />
                  </figure>
                </div>

                <!--VIDEO-->
                <div 
                  class="ratio ratio-16x9 mb-3"
                  v-if="content.url_video !== null && content.tipo_contenido[0].id_tipo_contenido == '3'"
                  >
                  <video 
                    :src="content.url_video" 
                    controls 
                    class="border"
                    alt="Video">
                  </video>
                </div>

                <!--DOCUMENT-->
                <div 
                  class="alert alert-secondary d-flex align-items-center justify-content-between p-2"
                  v-if="content.url_documento !== null && content.tipo_contenido[0].id_tipo_contenido == '4'"
                >
                  <span>
                    <font-awesome-icon icon="file" class="color-green"/>
                    Documento
                  </span>
                  <a 
                    :href="content.url_documento" 
                    target="_blank"
                    class="btn btn-secondary btn-sm"
                  >
                    <font-awesome-icon icon="download"/>
                      {{ content.texto }}
                  </a>
                </div>

                <!--USER-->
                <div 
                  class="user" 
                  v-if="content.usuario.length > 0 && content.tipo_contenido[0].id_tipo_contenido == '5'"
                >
                  <div 
                    class="detail" 
                    v-for="(user, y) in content.usuario" 
                    :key="y"
                  >
                    <img 
                      class="autor-img" 
                      :src="user.imagen_perfil ? user.imagen_perfil : user_default_img" 
                      height="20"
                    />
                    <div class="autor-container">
                      <div class="autor-title">Autor</div>
                      <div class="autor">
                        {{ user.primer_nombre + " " + user.apellido_paterno }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-custom btn-custom-color-blue border-round-50" 
              @click="editNoticia(noticia_selected)"
              data-bs-dismiss="modal"
            >
              <font-awesome-icon icon="pen" class="pe-2"/>
              Editar
            </button>
            <button
              type="button" 
              class="btn btn-custom btn-custom-color-red border-round-50" 
              @click="openDeleteModal(noticia_selected)"
            >
              <font-awesome-icon icon="trash-alt" class="pe-2"/>
              Eliminar
            </button>              
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pregunta -->
    <Question 
      v-if="open_question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <!--  Modal que muestra el estado de la accion (exito o error) -->
    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status"
      @close="closeStatus"
    />


<!--     <div class="news-list">
      <div class="news-list__top">
        <div class="news-list__top-title">
          <h3 class="news-list__sub-title my-0">
            Conoce todas las noticias que están publicadas en LinkiWork. Podrás
            <strong>revisar</strong>, <strong>editar</strong> o
            <strong>eliminar</strong> alguna si lo requieres.
          </h3>
          <router-link to="/noticias/add" class="news-list__add-button my-0">+ Crear noticia</router-link>
        </div>
        <div class="news-list-top-filter">
          <div class="left">
            <div class="left-searcher">
              <div class="left-searcher-container">
                <input 
                  type="text"
                  placeholder="Buscar noticia" 
                  v-model.trim="search_news" 
                  @keyup.enter="searchNewsNE()"
                  @keyup.delete="checkEmptyInput()"/>
                <div class="icon">
                  <img src="@/assets/img/Buscador.svg" height="23" @click="searchNewsNE()" alt="" />
                </div>
              </div>
            </div>
            <div class="select-container" :class="open_select && 'active'">
              <div class="select-btn" @click="toggleSelect()">
                <div class="cat-selected">
                  {{ cat_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon :icon="open_select ? 'angle-up' : 'angle-down'"></font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_select">
                <div v-for="(cat, k) in categorias_list" :key="k" class="cat-item" @click="selectCategoria(cat, k)">
                  {{ cat.nombre_categoria }}
                </div>
              </div>
            </div>
          </div>
          <div class="filter-container">
            <div class="title">Filtrar por periodo:</div>
  
            <div class="calendar-container" v-click-outside="handleCalendar">
              <div class="calendar" :class="calendar_side" v-if="show_calendar">
                <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
                  v-model="date_init" @dayclick="onDayClick" />
                <div class="border-arrow" :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div @click="
                (end_date = 'Término'),
                  (init_date = 'Inicio'),
                  (inicio = ''),
                  (fin = ''),
                  searchNewsNE(),
                  (show_calendar = false)
              " v-show="init_date != 'Inicio'" class="survey-list__close-start">
                <font-awesome-icon icon="times"></font-awesome-icon>
              </div>
              <div class="start" @click="openCalendar('start-date')">
                <span>{{ init_date }}</span>
  
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div class="end unactive" v-if="init_date == 'Inicio'">
                <span>{{ end_date }}</span>
  
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div @click="
                (end_date = 'Término'),
                  (init_date = 'Inicio'),
                  searchNewsNE(),
                  (show_calendar = false)
              " v-show="end_date != 'Término'" class="survey-list__close-end">
                <font-awesome-icon icon="times"></font-awesome-icon>
              </div>
              <div class="end" v-if="init_date != 'Inicio'" @click="openCalendar('end-date')">
                <span>{{ end_date }}</span>
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="news-list-container">
        <div class="list-news search-result" id="list_news_results" ref="list"
          v-show="newsArr.length > 0 && no_results == ''">
          <div class="list-news__news-container" v-for="(n, i) in newsArr" :key="i" :class="
            i % selected_order.amount == selected_order.amount - 1
              ? `margin-rigth-none`
              : ``
          " :style="`width:${width_items}px;`">
            <div class="item">
              <div class="item-img">
                <img :src="n.url_imagen_principal" alt="" />
              </div>
              <div class="item-date" v-if="futureNew(n.fecha_noticia)">
                <p>
                  Esta noticia se publicará <br />el
                  <strong>{{ dateNewFormat(n.fecha_noticia, 1) }}</strong><br />
                  a las <strong>{{ hourFormat(n.fecha_noticia) }}</strong>
                </p>
              </div>
              <div class="item-icons">
                <span class="watch" content="Ver noticia" v-tippy="{ arrow: true }"
                  @click="(noticia_selected = n), (open_modal = true)">
                  <font-awesome-icon icon="eye"></font-awesome-icon>
                </span>
                <span class="edit" content="Editar noticia" v-tippy="{ arrow: true }" @click="editNoticia(n)">
                  <font-awesome-icon icon="pen"></font-awesome-icon>
                </span>
                <span class="delete" content="Eliminar noticia" v-tippy="{ arrow: true }" @click="openDeleteModal(n)">
                  <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                </span>
              </div>
              <div class="item-title">
                {{ n.titulo_noticia }}
              </div>
              <div class="list-news__container-date">
                <p class="video-section__album-date">
                  {{ dateNewFormat(n.fecha_noticia, 1) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-bottom"></div>

        <div class="no-results" v-show="no_results !== ''">
          {{ no_results }}
        </div>
      </div>
  
      <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
        <paginate v-model="pagination.actual_page" :page-count="pagination.page_count" :page-range="pagination.page_range"
          :click-handler="pagination.click_handler" :prev-text="pagination.prev_text" :next-text="pagination.next_text"
          :container-class="pagination.container_class" :page-class="pagination.page_class">
        </paginate>
      </div>
      <div class="modal" v-if="open_modal">
        <div class="modal-container">
          <div class="modal-container-top">
            <div class="close" @click="open_modal = false">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </div>
            <div class="top-container">
              <img :src="noticia_selected.url_imagen_principal" alt="" />
              <div class="title">
                {{ noticia_selected.titulo_noticia }}
                <div class="triangulo"></div>
              </div>
              <div class="date">
                <div v-for="(dt, i) in dateNewFormat(
                  noticia_selected.fecha_noticia,
                  2
                ).split(';')" :key="i" :class="`date-${i == 0 ? 'a' : 'b'}`">
                  {{ dt }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-container-main">
            <div class="contenido">
              <div v-for="(content, k) in noticia_selected.contenido" :key="k">
                <div class="text" v-if="
                  content.texto !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '1'
                " v-html="content.texto"></div>
                <div class="image" v-if="
                  content.url_imagen !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '2'
                ">
                  <a target="_blank" :id="`img[${k}]`" :href="content.url_externo_intranet"
                    v-if="content.url_externo_intranet != ''">
                    <img :src="content.url_imagen" alt="" /></a>
  
                  <img :src="content.url_imagen" alt="" v-if="content.url_externo_intranet == ''" />
                </div>
                <div class="video" v-if="
                  content.url_video !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '3'
                ">
                  <video :src="content.url_video" autoplay controls alt="Video"></video>
                </div>
                <div class="document" v-if="
                  content.url_documento !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '4'
                ">
                  <a :href="content.url_documento" target="_blank">
                    Ver documento: {{ content.texto }}
                    <span>
                      <img src="@/assets/img/Descarga.svg" height="20" alt="" />
                    </span>
                  </a>
                </div>
                <div class="user" v-if="
                  content.usuario.length > 0 &&
                  content.tipo_contenido[0].id_tipo_contenido == '5'
                ">
                  <div class="detail" v-for="(user, y) in content.usuario" :key="y">
                    <img class="autor-img" :src="user.imagen_perfil ? user.imagen_perfil : user_default_img" height="20"
                      alt="" />
                    <div class="autor-container">
                      <div class="autor-title">Autor</div>
                      <div class="autor">
                        {{ user.primer_nombre + " " + user.apellido_paterno }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-container-footer">
            <div content="Editar noticia" v-tippy="{ arrow: true }" class="modal-container-footer-icon"
              @click="editNoticia(noticia_selected)">
              <font-awesome-icon icon="pen"></font-awesome-icon>
            </div>
            <div content="Eliminar noticia" v-tippy="{ arrow: true }" class="modal-container-footer-icon"
              @click="openDeleteModal(noticia_selected)">
              <font-awesome-icon icon="trash-alt"></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
  
      <Question v-if="open_question_modal" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelQuestion"
        @accept="acceptQuestion" />
      <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="closeStatus" />
    </div> -->
  </section>

</template>

<script>
import { mapState, mapActions } from "vuex";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
export default {
  components: { Spinner, Question, Status },
  data() {
    return {
      user_default_img: require("@/assets/img/user_default_img.svg"),
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      meses: [
        "en",
        "febr",
        "mar",
        "abr",
        "my",
        "jun",
        "jul",
        "agt",
        "set",
        "oct",
        "nov",
        "dic",
      ],
      open_select: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_categoria: "0",
      especial_categoria: "1",
      open_modal: false,
      noticia_selected: {},
      show_spinner: false,
      news_list_search: [],
      no_results: "",
      search_news: "",
      cat_selected: "Filtrar categoría",
      question_modal_msg: "¿Deseas eliminiar esta noticia?",
      open_question_modal: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      noticias: [],
      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      inicio: "",
      fin:'',
      max_date: "",
      min_date: "",
      order_list: [
        {
          amount: 1,
          size: 768,
        },
        {
          amount: 2,
          size: 1280,
        },
        {
          amount: 3,
          size: 1366,
        },
        {
          amount: 3,
          size: 1580,
        },
        {
          amount: 4,
          size: 1600,
        },
        {
          amount: 5,
          size: 1920,
          width: "",
        },
        {
          amount: 5,
          size: 1920,
          width: "",
        },
      ],
      selected_order: {
        amount: 0,
        size: 0,
      },
      date_init: "",
      width_items: 200,
      img_calendar: require("@/assets/img/utilities/calendar.svg"),

      page: 1,
      page_total: 0,
      page_limit: "30",
      page_more: true,
      page_data: [{ last_id: "", last_fecha: "" }],
      page_last: { last_id: "", last_fecha: "" },
      page_elements: [],

      //FF
      sel_categoria: '',
      data_categoria: [],
    };
  },
  watch: {
    sidebar_open() {
      this.getSceenWidth();
    },
    //FF
    sel_categoria(value) {
      this.categoria = value.id;
      this.id_categoria = value.id;
      this.cat_selected = value.label;
      this.searchNewsNE()
    }
  },
  created() {
    //FF
    this.getCategoria();
    try {
      window.addEventListener("resize", this.getSceenWidth);
      this.$refs.list.clientWidth.addEventListener(
        "resize",
        this.getSceenWidth
      );
    } catch (err) {
      if (err) {
        //console.log(".");
      }
    }
  },
  destroyed() {
    try {
      window.removeEventListener("resize", this.getSceenWidth);
      this.$refs.list.clientWidth.removeEventListener(
        "resize",
        this.getSceenWidth
      );
    } catch (err) {
      if (err) {
        //console.log(".");
      }
    }
  },
  computed: {
    ...mapState("newsModule", [
      "news_list",
      "news_list_add",
      "sidebar_open",
      "news_deleted",
      "paginationArr",
      "newsArr",
      "categories"
    ]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },

    categorias_list() {
      return this.categories;
    }
  },

  async mounted() {
    this.selectCategoria(
      { id_categoria: "0", nombre_categoria: "Filtrar categoría" },
      1
    );
  },

  methods: {
    ...mapActions("newsModule", [
      "deleteNews", 
      "getNewsPagination",
      "getNewsPaginated",
      "getNewsCategories"
      ]), 
    async getNewsPages(){
      let data_ = {
        nombre_noticia: this.search_news.toLowerCase(),
        id_empresa: this.id_empresa,
        id_categoria: this.id_categoria,
        fecha_inicio: this.inicio,
        fecha_fin: this.fin,
        limite: this.page_limit
      }
      await this.getNewsPagination(data_);
      this.pagination.page_count = this.paginationArr.length;
    },
    async getNewsByPage(page){
     this.show_spinner = true;
      let data_ = {
        id_usuario:  this.$ls.get("user").id_usuario,
        id_empresa: this.id_empresa,
        id_noticia: this.paginationArr[(page - 1)].noticias,
      }
      await this.getNewsPaginated(data_)
      this.show_spinner = false;
    },
    async searchNewsNE(){
      this.show_spinner = true;
      await this.getNewsPages();
      if(this.paginationArr.length > 0){
        this.pagination.actual_page = 1;
        await this.getNewsByPage(this.pagination.actual_page);
        this.no_results = "";
        this.show_spinner = false;
      }
      else{
        this.no_results = this.$noResults;
        this.show_spinner = false;
      }
      setTimeout(() => {
        this.getSceenWidth();
      }, 400);
    },
    async changePage(page){
      this.show_spinner = true;
      this.pagination.actual_page = page;
      await this.getNewsByPage(this.pagination.actual_page);
      this.show_spinner = false;
    },

    selectCategoria(cat, k) {
      if (k != -1) {
        this.categoria = cat.id_categoria;
        this.id_categoria = cat.id_categoria;
        this.cat_selected = cat.nombre_categoria;
        setTimeout(() => {
          this.open_select = false;
        }, 100);
      }
      this.searchNewsNE()
    },
    
    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.date_start = '';
      this.date_end = '';
      this.inicio = '';
      this.fin = '';
      this.searchNewsNE();
      this.show_calendar = false;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        let min = new Date(this.init_date);
        this.min_date = new Date(min.setDate(min.getDate() + 2));
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.min_date;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
        this.inicio = day.id;
      } else {
        this.end_date = day.id;
        this.fin = day.id;
      }
      if (this.init_date != "Inicio" && this.end_date != "Término") {
        this.searchNewsNE();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      this.show_calendar = false;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    // funcion para abrir modal para preguntar si se elimina la noticia
    openDeleteModal(noticia) {
      this.noticia_selected = noticia;
      this.open_question_modal = true;
    },
    // Funcion para "cancelar" la pregunta del Modal Question
    cancelQuestion() {
      this.open_question_modal = false;
    },
    // Funcion para "aceptar" la pregunta del modal Question (eliminar usuario)
    async acceptQuestion() {
      this.open_question_modal = false;
      this.show_spinner = true;
      this.open_modal = false;
      let data = {
        id_empresa_fk: this.id_empresa,
        id_noticia: this.noticia_selected.id_noticia,
        id_eliminador: this.$ls.get("user").id_usuario,
      }
      await this.deleteNews(data).then(res =>{
        if(res != null) this.$toast.open({
          message: 'Noticia eliminada exitosamente.',
          type: 'success',
          duration: 6000,
          position: 'top-right'
        });
        else this.$toast.open({
          message: 'Ocurrió un error al eliminar la noticia, intentalo nuevamente.',
          type: 'error',
          duration: 0,
          position: 'top-right'
        });
      });
      this.show_spinner = false;
      
    },
    // Funcion para cerrar modal de status
    closeStatus() {
      this.open_modal_status = false;
    },
    // Funcion que abre componente "Add" para editar una noticia
    editNoticia(news) {
      this.$router
        .push({
          name: "news-edit",
          params: { id: news.id_noticia },
        })
        .catch(() => {
          return;
        });
    },
    //Fecha futura
    futureNew(date) {
      let day = new Date(date);
      let toDay = new Date();
      return day > toDay;
    },
    dateFormat(date) {
      if (date != 'Inicio' && date != 'Término') {
        let new_date = new Date(date + 'T00:00:00');
        let month = (new_date.getMonth() + 1) < 10 ? '0'+(new_date.getMonth() + 1):(new_date.getMonth() + 1);
        let year = new_date.getFullYear();
        let day = new_date.getDate() < 10 ? '0'+ new_date.getDate(): new_date.getDate();
        return day + '-' + month + '-' + year;
      } else {
        return date;
      }
    },
    //Formatear fecha
    dateNewFormat(date, type) {
      let day = new Date(date);
      let month = day.getMonth();
      if (type == 1) {
        return `${day.getDate()}/${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }/${day.getFullYear()}`;
      }
      if (type == 2) {
        return `${day.getDate()};${this.meses[month]}`;
      }
    },
    // Cambiar a esta funcion
    getDateNewFormat(date) {
      let day = new Date(date);
      let month = day.getMonth() + 1;
      if (month.toString().length == 1) month = "0" + month;
      return day.getDate() + "/" + month + "/" + day.getFullYear();
    },
    //Formatear Hora
    hourFormat(date) {
      let day = new Date(date);
      let hours = day.getHours();
      let min = day.getMinutes();
      return `${hours < 10 ? `0${hours}` : hours}:${
        min < 10 ? `0${min}` : min
      }`;
    },
    // funcion para abrir y cerrar select de categorias
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },
    getSceenWidth() {
      try {
        let size_ = window.innerWidth;
        let container_magin = 30;
        let container_width = this.$refs.list.clientWidth;
        for (let i = 0; i < this.order_list.length; i++) {
          let before = 0;
          if (i < 1) {
            if (size_ < this.order_list[i].size) {
              this.selected_order = this.order_list[i];
              break;
            }
          } else {
            before = i - 1;
          }
          if (
            size_ > this.order_list[before].size &&
            size_ <= this.order_list[i].size
          ) {
            this.selected_order = this.order_list[i];
            break;
          }
          if (i == this.order_list.length - 1) {
            if (size_ > this.order_list[i].size) {
              this.selected_order = this.order_list[i];
              break;
            }
          }
        }
        let final_container_width =
          container_width - container_magin * (this.selected_order.amount - 1);
        let item_width = final_container_width / this.selected_order.amount;
        this.width_items = item_width - 8;
      } catch (err) {
        if (err) {
          //console.log(".");
        }
      }
    },
    checkEmptyInput(){
      if(this.search_news == ''){
        this.searchNewsNE();
      }
    },

    //FF
    async getCategoria() {
      await this.getNewsCategories({ id_empresa: this.$ls.get("user").empresa[0].id_empresa }).then(res => {
          let data = [];
          res.forEach((element) => data.push({
            id: element.id_categoria,
            type: element.__typename,
            label: element.nombre_categoria
          }));
          console.log(data);
          this.data_categoria = data;
        })
    }
  },
};
</script>
